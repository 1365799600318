const APIS = {
  saveCustomMessage: ['document/formcustom/save-custom-message', 'post'],
  getDocumentJson: ['document/detail/indexJson', 'get'],
  getLeftMenu: ['document/detail/get-left-menu', 'get'],
  getSearchDetail: ['document/search/get-search-info', 'get'],
  clearCache: ['document/document/clear-cache', 'get'],
  getAccessToken: ['document/document/get-guest-token', 'get'],
  jumpDebugger: ['document/debug/get-jump-url', 'get'],
  uploadDoc: ['document/document/upload', 'post'],
  getDocList: ['document/document/lists', 'post'],
  getDocChapterInfo: ['document/document/vod-doc-info', 'post'],
  createChapterInfo: ['document/document/submit-create-doc', 'post'],
  getDocChapterEditInfo: ['document/document/get-edit-doc-info', 'post'],
  delDoc: ['document/document/submit-delete-doc', 'post'],
  updateDocChapter: ['document/document/submit-update-docInfo', 'post'],
  commitFeedBack: ['/document/feedback/commit', 'post']

}
const getApi = api => {
  return APIS[api] && APIS[api]
}

export default getApi
