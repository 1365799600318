import getApi from './api'
import stringify from 'qs/lib/stringify'
const base_url = process.env.VUE_APP_API_url

export default function fetchData(url, data = {}, headers = {}) {
  const [api, method] = getApi(url)
  let full_url = ''

  const option = { method }
  const defaultHeaders = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  }
  if (headers['Content-Type'] != 'multipart/form-data') {
    Object.assign(option, defaultHeaders)
  }
  if (method.toLowerCase() === 'get') {
    const paramsArr = []
    Object.entries(data).forEach(el => {
      paramsArr.push(el.join('='))
    })
    const paramsStr = paramsArr.join('&')
    full_url = `${base_url}${api}?${paramsStr}`
  }

  if (method.toLowerCase() === 'post') {
    if (headers['Content-Type'] === 'multipart/form-data') {
      const formData = new FormData()
      for (const key in data) {
        formData.append(key, data[key])
      }
      option.body = formData
    } else {
      option.body = stringify(data, { arrayFormat: 'brackets' })
    }

    full_url = `${base_url}${api}`
  }

  return fetch(full_url, option)
    .then(res => {
      return res.json()
    })
    .then(data => {
      if (data.code == 200) {
        return data
      } else {
        return Promise.reject(data)
      }
    })
    .catch(error => {
      return Promise.reject(error)
    })
}
