<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'app'
}
</script>

<style>
html {
  scroll-behavior: smooth;
}
#app {
  height: 100%;
  font-family: PingFangSC-Regular, PingFang SC, 'Avenir', Helvetica, Arial,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #555;
}
</style>
