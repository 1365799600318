import Vue from 'vue'
import 'element-ui/lib/theme-chalk/index.css'
import {
  Button,
  Menu,
  Submenu,
  MenuItem,
  Image,
  Carousel,
  CarouselItem,
  Pagination,
  Form,
  FormItem,
  Input,
  Select,
  Option,
  Message,
  MenuItemGroup,
  Loading,
  Dialog,
  Table,
  TableColumn,
  Progress,
  Checkbox,
  Upload
} from 'element-ui'
// 解决弹窗导致页面抖动
Dialog.props.lockScroll.default = false
Vue.use(Button)
Vue.use(Menu)
Vue.use(Submenu)
Vue.use(MenuItem)
Vue.use(Image)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Pagination)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Select)
Vue.use(Option)
Vue.use(MenuItemGroup)
Vue.use(Loading)
Vue.use(Dialog)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Progress)
Vue.use(Checkbox)
Vue.use(Upload)

Vue.prototype.$message = Message
