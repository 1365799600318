import Vue from 'vue'
import VueRouter from 'vue-router'

// 处理多次点击同一个路由报错
const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error)
}
Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes: [{
    path: '/',
    redirect: '/index'
  },
  {
    path: '/Vmeeting/:prefix?/:id?',
    name: 'Vmeeting',
    component: () =>
        import ('./views/webInteractive/index.vue')
  },
  {
    path: '/client-download',
    name: 'client-download',
    component: () =>
        import ('./views/webInteractive/downloadClient.vue')
  },
  {
    path: '/',
    component: () =>
        import ('./views/layout/index.vue'),
    children: [{
      path: 'index',
      name: 'index',
      component: () =>
            import ('./views/index/index.vue'),
      meta: {
        title: '微吼云-企业视频云平台 |云点播|云直播|音视频互动|IM一站式视频云解决方案-微吼直播'
      }
    },
    {
      path: 'live',
      name: 'live',
      component: () =>
            import ('./views/service/live/index.vue'),
      meta: {
        title: '直播云_云直播_直播SDK_微吼云直播服务LSS-微吼直播'
      }
    },
    {
      path: 'vod',
      name: 'vod',
      component: () =>
            import ('./views/service/vod/index.vue'),
      meta: {
        title: '云点播_云视频点播_播放器SDK_微吼云点播VOD-微吼直播'
      }
    },
    {
      path: 'interactive',
      name: 'interactive',
      component: () =>
            import ('./views/service/interactive/index.vue'),
      meta: {
        title: '音视频互动云LVB_多人互动连麦_webrtc_微吼云音视频互动解决方案-微吼直播'
      }
    },
    {
      path: 'document',
      name: 'document',
      component: () =>
            import ('./views/service/document/index.vue'),
      meta: {
        title: '[云文档_云互动文档_云文档白板_微吼云文档-微吼直播]'
      }
    },
    {
      path: 'chat',
      name: 'chat',
      component: () =>
            import ('./views/service/chat/index.vue'),
      meta: {
        title: '微吼云-IM云服务_云通信_IM云_大规模实时消息-微吼直播'
      }
    },
    {
      path: 'form',
      name: 'form',
      component: () =>
            import ('./views/service/form/index.vue'),
      meta: {
        title: ''
      }
    },
    {
      path: 'enjoy',
      name: 'enjoy',
      component: () =>
            import ('./views/solution/enjoy-customization/index.vue'),
      meta: {
        title: ''
      }
    },
    {
      path: 'live-answer',
      name: 'live-answer',
      component: () =>
            import ('./views/solution/live-answer/index.vue'),
      meta: {
        title: '直播答题_直播答题系统_知识竞答_微吼云直播答题-微吼直播'
      }
    },
    {
      path: 'live-cloud-meeting',
      name: 'live-cloud-meeting',
      component: () =>
            import ('./views/webInteractive/downloadClient.vue'),
      meta: {
        title: '直播答题_直播答题系统_知识竞答_微吼云直播答题-微吼直播'
      }
    },
    {
      path: 'live-mini-program',
      name: 'live-mini-program',
      component: () =>
            import ('./views/solution/live-mini-program/index.vue'),
      meta: {
        title: '直播答题_直播答题系统_知识竞答_微吼云直播答题-微吼直播'
      }
    },
    {
      path: 'custom',
      name: 'custom',
      component: () =>
            import ('./views/solution/custom.vue'),
      meta: {
        title: '直播答题_直播答题系统_知识竞答_微吼云直播答题-微吼直播'
      }
    }
    ]
  },
  {
    path: '/search/:keyword/:doc_type?',
    name: 'search',
    component: () =>
        import ('./views/document-center/result.vue')
  },
  {
    path: '/docs/show/:id/:link?',
    name: 'docs/show',
    component: () =>
        import ('./views/document-center/detail.vue')
  },
  {
    path: '/document/document/index',
    name: 'doc-center',
    component: () =>
        import ('./views/document-center/index.vue')
  },
  {
    path: '/chapter-demo/',
    component: () =>
        import ('./views/doc-chapter-demo/index.vue'),
    children: [{
      path: 'select-doc',
      name: 'select-doc',
      component: () =>
            import ('./views/doc-chapter-demo/component/select-doc.vue')
    },
    {
      path: 'chapter-edit/:isEdit/:doc_ids',
      name: 'chapter-edit',
      component: () =>
            import ('./views/doc-chapter-demo/component/chapter-edit.vue')
    },
    {
      path: 'chapter-list/:doc_ids',
      name: 'chapter-list',
      component: () =>
            import ('./views/doc-chapter-demo/component/chapter-list.vue')
    }
    ]
  }
  ],
  scrollBehavior(to, from, savedPosition) {
    return { y: 0 }
  }
})

export default router
