import Vue from 'vue'
import App from './App.vue'
import './element.js'
import router from './router'
import './assets/less/reset.css'
import './assets/less/common.less'
import './assets/font/iconfont.css'
import './assets/less/zenburn.css'
import fetchData from './api'
Vue.prototype.$fetch = fetchData

window.urlConfig = {
  consoleUrl: process.env.VUE_APP_CONSOLE_path,
  docUrl: process.env.VUE_APP_Doc_url,
  apiTestUrl: process.env.VUE_APP_API_test_url
}

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
